import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import './scss/App.scss';

function App() {
  return (
    <BrowserRouter className="App content">
      <div className='content'>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
