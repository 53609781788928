import React, { useState, useRef } from 'react';
import AudioWaveform from './AudioWaveform'; // Import the waveform component
import styles from './scss/FileUploader.module.scss';
import { isValidUrl } from './utils/utils'; 

const FileUploader = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const [fileSize, setFileSize] = useState("");
  const [fileDuration, setFileDuration] = useState("");
  const audioRef = useRef(new Audio());
  const wavesurfer = useRef(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [tempAudioUrl, setTempAudioUrl] = useState('');

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
    return [hours, minutes, seconds]
      .map(val => val < 10 ? `0${val}` : val)
      .join(':');
  };

  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(1); // Convert to MB and keep one decimal
  };

  const handleUrlSubmit = async (tempUrl) => {
    console.log("Load Audio clicked");

    if (isValidUrl(tempAudioUrl)) {
      console.log("Loading audio from URL:", tempAudioUrl);

      setAudioUrl(tempAudioUrl); // Update the state for future reference
      setFile(null); // Clear any previously selected file
      setFileName('');
      setFileDuration('');

      // Attempt to get file size
      try {
        console.log("Fetching file size for URL");
        const response = await fetch(tempAudioUrl, { method: 'HEAD' });
        const contentLength = response.headers.get('content-length');
        if (contentLength) {
          console.log("File size retrieved:", contentLength, "bytes");
          setFileSize(`${contentLength} bytes (${bytesToMB(contentLength)} MB)`);
        } else {
          console.log("Content-Length header is not present in the response");
          setFileSize('Unknown Size');
        }
      } catch (error) {
        console.error('Error fetching file size:', error);
        setFileSize('Unable to determine size');
      }

      // Load the audio to get duration
      const audio = new Audio();
      audio.src = tempAudioUrl;
      audio.addEventListener('loadedmetadata', () => {
        console.log("Audio metadata loaded, duration:", audio.duration, "seconds");
        setFileDuration(formatDuration(audio.duration));
      });

      // Load audio into WaveSurfer if used
      if (wavesurfer.current && tempAudioUrl) {
        wavesurfer.current.load(tempAudioUrl);
      } else {
        console.log("No URL provided");
      }
    } else {
      console.log("Invalid URL provided:", tempAudioUrl);
    }
  };


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setTempAudioUrl('');
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFileSize(`${selectedFile.size} bytes (${bytesToMB(selectedFile.size)} MB)`);

      audioRef.current.src = URL.createObjectURL(selectedFile);
      audioRef.current.addEventListener('loadedmetadata', () => {
        setFileDuration(formatDuration(audioRef.current.duration));
      });

      // Only create a new URL if a file is selected
      const reader = new FileReader();
      reader.onloadend = () => {

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        audioContext.decodeAudioData(reader.result)
          .then((decodedData) => {
            // Use the decoded data with WaveSurfer
            if (wavesurfer.current) {
              wavesurfer.current.loadBlob(new Blob([decodedData]));
            }
          })
          .catch((error) => {
            console.error('Error decoding audio data', error);
          });
      };
      reader.readAsArrayBuffer(selectedFile);

    }
  };

  return (
    <div className={styles.fileUploaderContainer}>
      <h1 className="text-4xl md:text-6xl font-bold text-center mb-8">
        Audio Meta Detector
      </h1>
      <p className={`${styles.explainerText} mb-6`}>
        Select your local audio (or video) file or specify a file URL.
        Our system will extract the file size and duration, giving you with the meta data.
      </p>

      <div className='flex flex-col md:flex-row mb-5 items-stretch w-full sm:w-1/2 mx-auto'>
        <input
          type="text"
          placeholder="Paste external audio URL here"
          value={tempAudioUrl}
          onChange={(e) => setTempAudioUrl(e.target.value)}
          className='flex-grow p-3 border-2 border-black'
        />
        <button
          onClick={() => handleUrlSubmit(tempAudioUrl)}
          className={`${styles.fileInputLabel} whitespace-nowrap`}>
          Check Audio URL
        </button>
      </div>

      <input
        type="file"
        onChange={handleFileChange}
        id="file"
        accept="audio/*"
        className={styles.hiddenInput}
      />
      <label htmlFor="file" className={styles.fileInputLabel}>
        Upload Audio File
      </label>

      {(file || audioUrl) && (
        <div className='mt-6'>
          <div className={`${styles.fileNameDisplay} my-2 text-xl`}>
            {fileName || new URL(audioUrl).pathname.split('/').pop()}
          </div>
          <div className={`${styles.fileInfoDisplay} my-2 text-2xl`}>
            Duration: {fileDuration}
          </div>
          <div className={`${styles.fileSizeDisplay} my-2 text-2xl`}>
            Size: {fileSize}
          </div>
          <AudioWaveform audioFile={file || audioUrl} />
        </div>
      )}

    </div>
  );
};

export default FileUploader;
