import React from 'react';
import { Link } from 'react-router-dom';
import styles from './../scss/Header.module.scss';

const Header = () => {
  return (
    <header className={`fixed flex items-center p-4 pl-7 ${styles.headerContainer}`}>
      <Link to="/">
        <img src="/logo-audio-meta.svg" alt="Audio Meta Detector Logo" className="h-12 w-12 transform hover:rotate-90 transition duration-200" />
      </Link>
    </header>
  );
};

export default Header;
