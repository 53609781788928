import React, { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';

const AudioWaveform = ({ audioFile }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  useEffect(() => {
    console.log("Initializing WaveSurfer");

    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'black',
        progressColor: 'orange',
      });

      wavesurfer.current.on('ready', () => {
        console.log("WaveSurfer is ready");
        // wavesurfer.current.drawBuffer(); // Manually trigger waveform rendering, if necessary.
      });

      wavesurfer.current.on('error', (error) => {
        console.error('WaveSurfer error:', error);
      });

      return () => {
        console.log("Destroying WaveSurfer");
        wavesurfer.current.destroy();
      };
    } else {
      console.error("Waveform container not found");
    }
  }, []);

  useEffect(() => {
    console.log("AudioFile prop has changed", { audioFile });

    if (audioFile && wavesurfer.current) {
      console.log("Loading new audio file into WaveSurfer");

      // Assuming audioFile is already a Blob if coming from an input file element,
      // otherwise, adjust accordingly.
      wavesurfer.current.loadBlob(audioFile);

      // Alternatively, if audioFile is a URL, use:
      // wavesurfer.current.load(audioFile);
    }
  }, [audioFile]);

  return <div ref={waveformRef} className="audioWaveformContainer" />;
};

export default AudioWaveform;
