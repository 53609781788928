// src/pages/Home.js
import React from 'react';
import FileUploader from '../FileUploader';

const Home = () => {
  return (
    <>
      <FileUploader />
    </>
  );
};

export default Home;
