import React from 'react';
import { Link } from 'react-router-dom';
import styles from './../scss/Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <p className='text-xs mb-3'>Stick to the vibe with our stark black and yellow interface.</p>
      <p>© {new Date().getFullYear()} <Link to="/">Audio Meta Detector</Link>. All rights reserved.</p>
      <p>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </p>
    </footer>
  );
};

export default Footer;
